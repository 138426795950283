import React, { Suspense, lazy } from "react";

import { ErrorBoundary } from "./components/Error";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Card from "./components/Card";
import WalletProvider from "./store/walletProvider";

const WalletsRoutes = lazy(() => import("./components/Wallets"));
const AuthRoutes = lazy(() => import("./components/Authentication"));
const AppRoute = () => {
  return (
    <Card>
      <BrowserRouter>
        <WalletProvider>
          <ErrorBoundary>
            <Suspense fallback="">
              <Routes>
                <Route path="/wallet/*" element={<WalletsRoutes />} />
                <Route path="/*" element={<AuthRoutes />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </WalletProvider>
      </BrowserRouter>
    </Card>
  );
};

export default AppRoute;
