import React, { ReactNode, useEffect, useState } from "react";
import WalletContext, { CurrencyRate } from "./walletContext";
import { useNavigate } from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import { getCachedStorage, Storage } from "./utils";


const WalletProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [state, setState] = useState<Storage | undefined>();
  const [currencyRates, setCurrencyRates] = useState<CurrencyRate[]>([{ currency: 'SATS', rate: 1 }]);
  const [activeCurrencyRate, setActiveCurrencyRate] = useState<CurrencyRate>({ currency: 'SATS', rate: 1 })
  const [viewingRecieveBalance, setViewingRecieveBalance] = useState<boolean>(false);

  useEffect(() => {
    if (state?.user?.active) {
      const userData = state.user;
      Bugsnag.setUser(userData?.username, userData?.email, userData?.fullName);
    }
    // eslint-disable-next-line
  }, [state?.user?.active]);


  useEffect(() => {
    const allowedAuthPaths = ["/signin", "/signup", "/forgotpassword", "/changepassword"];
    const currentPath = window.location.pathname;

    if (state?.token) {
      const allowedPaths = [
        "/wallet",
        "/wallet/send",
        "/wallet/receive",
        "/transaction",
        "/wallet/transaction",
      ];
      if (!allowedPaths.includes(currentPath)) {
        navigate("/wallet");
      }
    } else if (!allowedAuthPaths.includes(currentPath) && currentPath.split("/")[1].toLowerCase() !== "changepassword") {
      navigate("/signin");
    }
    // eslint-disable-next-line
  }, [state?.token, navigate]);

  useEffect(() => {
    const store = getCachedStorage();
    if (store) {
      setState(store);
    }
  }, []);

  return (
    <WalletContext.Provider
      value={{
        state,
        setState,
        currencyRates,
        setCurrencyRates,
        activeCurrencyRate,
        setActiveCurrencyRate,
        viewingRecieveBalance,
        setViewingRecieveBalance
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
