import { Response } from "../api/registerUser";

export interface Storage extends Response { }

export const getCachedStorage = (): Storage | undefined => {
    const store: string | null = localStorage.getItem("wallet");
    if (store) {
        return JSON.parse(store)
    }
    return undefined
}

export const convertCurrencyAmount = (toCurrency: string, amount: number) : number => {
    if (toCurrency === 'Sats') return amount;

    if (toCurrency === 'BTC') return amount / 100000000;
    return amount;
}

