import React, { useContext } from "react";
import { LoginResponse } from "../api/fusionAPI";

export type Currency = 'SATS' | 'BTC' | 'USD';

export interface CurrencyRate {
    currency: Currency,
    rate: number
}

export interface Store {
    state?: LoginResponse,
    setState?: Function,
    currencyRates: CurrencyRate[],
    setCurrencyRates?: Function,
    activeCurrencyRate: CurrencyRate
    setActiveCurrencyRate?: Function
    viewingRecieveBalance: boolean
    setViewingRecieveBalance?: Function

}
const store: Store = {
    currencyRates: [{
        currency: 'SATS',
        rate: 1
    }],
    activeCurrencyRate: {
        currency: 'SATS',
        rate: 1
    },
    viewingRecieveBalance: false
}

const WalletContext = React.createContext(store);
WalletContext.displayName = "Wallet Context";
export const useWalletContext = () => useContext(WalletContext)
export default WalletContext;