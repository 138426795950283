import React, { ReactNode } from "react";
import "./index.css";

interface CardProps {
  style?: Object;
  children: ReactNode;
  className?: string;
}
function Card({ style = {}, children, className }: CardProps) {
  const pathname: string = window.location.pathname;
  console.log(pathname)
  return (
    <div style={style} className={`main__card ${className}`}>
      <div className="header">
        <div className="logo_container">
          <img
            src="/logo.svg"
            alt="LOGO"
            className="new_logo_image"
          />
          {/* <img
          className="logo__image"
          src="/logo_bull_ring.png"
          alt="Logo" /> */}
          <div className="logo_title_container">
            <p className="logo_title">
              <span className="logo_title_bold">BULLRING</span><br />
              <span className="logo_title_slim">FINANCE</span>
            </p>
          </div>

        </div>

        {pathname.split("/").includes("wallet") && <p className="heading_text font-archivo-500">Lightning wallet</p>}
      </div>
      {children}
    </div>
  );
}

export default Card;
